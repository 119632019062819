export const CUSTOMER = "/customer";
export const CUSTOMER_LICENSE = "/customer/license-info";
export const STATE = "/state";
export const ALL_CUSTOMER = "/customer/all-customers-orders-dynamic-search";
export const STATUS_FLAG = "/status-flags";
export const CUSTOMER_DOCUMENT = "/customer/document";
export const DELETE_CUSTOMER_DOCUMENT = "/customer/delete-document";
export const CUSTOMER_STATUS_FLAG = "customer/status-flag";
export const REMOVE_CUSTOMER_STATUS_FLAG = "customer/remove-status-flag";
export const CUSTOMER_INSURANCE_INFROMATION = "/customer/insurance-information";
export const DOCUMENT_TYPE = "/document-type";
export const CUSTOMER_DOCUMENT_REVIEW = "/customer/document/review";
export const CUSTOMER_BY_REFERENCE_ID = "/customer";
export const STATES = "/state";
export const COUNTRIES = "/countries";
export const CUSTOMER_THIRD_PARTY_RESPONSE_HISTORY =
  "/customer/third-party-response-history";
export const DIARY_CATEGORY = "/dairy-category";
export const ASSOSIATE_DAIRY_COMMENT = "/customer/comment";
export const ASSOSIATED_COMMENT_REFERENCE_ID = "/customer/comments";
export const DOCUMENT_PACKAGE_BY_REFERENCE_ID =
  "/configure/document-package-by-reference-id";
export const CUSTOMER_DOCUMENT_PACKAGE_BY_REFERENCE_ID =
  "/configure/all-document-packages-by-reference-id";
export const CUSTOMER_DOCUMENT_DOWNLOAD = "/customer/document/download";
export const SIGNED_URL = "/get-an-signed-url";
export const ACTIVE_DIRECTORY_USER = "/ad-user";
export const ADDITIONAL_DOCUMENT = "/customer/additional-document";
export const INSURANCE_DOCUMENT =
  "/customer/insurance-information/add-document";
export const INSURANCE_DOCUMENT_DELETE =
  "/customer/insurance-information/remove-insurance-document";
export const REASONS = "/reason";
export const CONTRACT = "/contract";
export const CONTRACT_ALL = "/contract/all";
export const CUSTOMER_MANDATORY_DISCLAIMERS =
  "/configure/customer-mandatory-disclaimers";
export const CUSTOMER_DISCLAIMER = "/configure/disclaimer-agreement";
export const GET_EVENT_ASSOCIATED_CHECKLIST =
  "/get_event_associated_checklist/";
export const ORDER_CHECKLIST = "order/order-checklist";
export const CUSTOMER_OVERALL_HISTORY = "/history";
export const HISTORY_DETAIL = "/history-details";
export const UPDATE_RETURN_REQUEST = "/return/update-request";
export const CREATE_RETURN_REQUEST = "/return/create-request";
export const UPDATE_RETURN_REUQUEST_STATUS = "/return/update-return-status";
export const CHECKLIST_RESPONSE_VERIFICATION =
  "/checklist_response_verification/";
export const PROCEED_RETURN_REQUEST = "/return/proceed-request";
export const OVERRUN_MILE_PENALTY = "/return/calculate-overrun-mile";
export const LATE_RETURN_PENALTY = "/return/late-return-penalty";
export const GET_REQUEST = "/return/get-requests";
export const CANCEL_RETURN_REQUEST = "/return/cancel-request";
export const STIPULATION_REASON = "/stipulation-reason";
export const GET_TENENT_CONFIG = "/external-keys/all";
export const CUSTOMER_DOCUMENT_PACKAGE = "/configure/document-package/document";
export const GET_TENENT_CONFIG_INFORMATION = "/lender_by_api_key";
export const DOWNLOAD_DOCUMENT = "/customer/document/download-stream"
export const GET_ORDER_STATUS = "an-order-statuses"
export const UNWIND_REASON = "/reason/filter"
//  ==================== Stripe API ==================== //
export const INVOICES_SEARCH = "/payment/invoices/search-by-metadata";
export const INVOICES = "/payment/invoices";
export const INVOICES_SEND = (invoiceId) =>
    `/payment/invoices/${invoiceId}/send`;
export const INVOICES_VOID = (invoiceId) =>
    `/payment/invoices/${invoiceId}/void`;
export const PRICES = "/payment/prices";
export const EVENTS = "/events";
export const INVOICE_ITEMS = "/payment/invoice-items";
export const INVOICES_CREDIT_NOTES = "/payment/invoice/credit-notes";
export const CREDIT_NOTE_PREVIEW = "/payment/credit-notes/preview";
export const CREDIT_NOTE = "/payment/credit-notes";
export const PRODUCTS_SEARCH = "/payment/products/search";
export const REFUNDS = "/payment/refunds";
export const PRODUCTS_BY_ID = "/payment/products";
export const UPCOMING_INVOICE = "/payment/invoices/upcoming";
export const CHARGE = "/payment/charges";

//  ==================== TAX API ==================== //
export const QUOTE_CREATE = "/quote/create";
